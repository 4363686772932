import { Tr, Td, Box, IconButton, Input, Flex, Select, Checkbox } from '@chakra-ui/react';
import { EditIcon, CheckIcon, CloseIcon, DeleteIcon } from '@chakra-ui/icons';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { usePrivileges } from '../../hooks/roles';
import { DangerButton } from './DangerButton';

type FieldType = 'string' | 'boolean' | 'enum';

type RowSchema = {
  [key: string]: {
    width?: string;
    edit: boolean;
    type: FieldType;
    options?: { value: string; label: string }[]; // Only for "enum"
  };
};

type TTableRowEdit = {
  row: Record<string, any>; // Whole row data
  schema: RowSchema; // Schema defining field types
  onSave: (updatedRow: Record<string, any>) => void; // Save handler
  onDelete?: () => void;
};

export const TableRowEdit: React.FC<TTableRowEdit> = ({ row, schema, onSave, onDelete }) => {
  const privileges = usePrivileges();
  const { t } = useTranslation(['shipment', 'common']);
  const [isEditing, setIsEditing] = React.useState(false);
  const [isHidden, setIsHidden] = React.useState(false);
  const [currentRow, setCurrentRow] = React.useState(row);

  const handleSave = () => {
    if (!privileges.PROJECT_SHIPMENTS.UPDATE) return;
    setIsEditing(false);
    // save only the fields that have changed to avoid unnecessary updates
    const changedFields = Object.keys(schema).filter((key) => currentRow[key] !== row[key]);
    const updatedRow = changedFields.reduce((acc, key) => ({ ...acc, [key]: currentRow[key] }), {});
    // if no fields have changed, do not save
    if (Object.keys(updatedRow).length === 0) return;

    onSave(updatedRow);
  };

  const handleCancel = () => {
    if (!privileges.PROJECT_SHIPMENTS.UPDATE) return;
    setIsEditing(false);
    setCurrentRow(row); // Reset to original row data
  };

  const handleChange = (key: string, value: any) => {
    if (!privileges.PROJECT_SHIPMENTS.UPDATE) return;
    setCurrentRow((prevRow) => ({ ...prevRow, [key]: value }));
  };

  const setEditingPrivileges = (value: boolean) => {
    if (!privileges.PROJECT_SHIPMENTS.UPDATE) return setIsEditing(false);
    return setIsEditing(value);
  };

  const handleDelete = () => {
    if (!privileges.PROJECT_SHIPMENTS.DELETE) return;
    onDelete && onDelete();
    setIsHidden(true);
  };

  if (isHidden) return <></>;

  return (
    <Tr
      _hover={{
        cursor: 'pointer',
      }}
      position="relative"
    >
      {Object.keys(schema).map((key) => (
        <Td key={key} width={schema[key].width || 'auto'}>
          {isEditing && schema[key].edit === true ? (
            (() => {
              const field = schema[key];
              switch (field.type) {
                case 'string':
                  return (
                    <Input
                      value={currentRow[key]}
                      onChange={(e) => handleChange(key, e.target.value)}
                      size="xs"
                      autoFocus
                    />
                  );
                case 'boolean':
                  return (
                    <Checkbox isChecked={currentRow[key]} onChange={(e) => handleChange(key, e.target.checked)}>
                      {t(`common:${key}`)}
                    </Checkbox>
                  );
                case 'enum':
                  return (
                    <Select value={currentRow[key]} onChange={(e) => handleChange(key, e.target.value)} size="xs">
                      {field.options?.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </Select>
                  );
                default:
                  return null;
              }
            })()
          ) : (
            <Box display="flex" justifyContent="space-between" alignItems="center">
              {(() => {
                const field = schema[key];
                if (field.type === 'boolean') {
                  return currentRow[key] ? 'Yes' : 'No';
                }
                if (field.type === 'enum') {
                  return field.options?.find((option) => option.value === currentRow[key])?.label || currentRow[key];
                }
                return currentRow[key];
              })()}
            </Box>
          )}
        </Td>
      ))}
      <Box>
        {isEditing ? (
          <Flex gap={2}>
            <IconButton
              variant="ghost"
              aria-label="Save"
              icon={<CheckIcon />}
              size="xs"
              colorScheme="green"
              onClick={handleSave}
            />
            <IconButton
              variant="ghost"
              aria-label="Cancel"
              icon={<CloseIcon />}
              size="xs"
              colorScheme="red"
              onClick={handleCancel}
            />
          </Flex>
        ) : (
          <>
            {privileges.PROJECT_SHIPMENTS.UPDATE && (
              <IconButton
                aria-label="Edit"
                icon={<EditIcon />}
                size="xs"
                ml={2}
                variant="ghost"
                onClick={() => setEditingPrivileges(true)}
              />
            )}
            {privileges.PROJECT_SHIPMENTS.DELETE && onDelete && (
              <DangerButton
                onClick={() => {
                  handleDelete();
                }}
                icon={<DeleteIcon />}
                centerIcon
                size="xs"
                title={t('common:delete.label')}
                showTitle={false}
                variant="ghost"
                body={t('shipment:delete.body') /*'Are you sure you want to delete this user?'*/}
                ml={2}
              />
            )}
          </>
        )}
      </Box>
    </Tr>
  );
};
