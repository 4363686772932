import { Table, Tbody, Tr, Td, TableContainer } from '@chakra-ui/react';
import { Shipment, ShipmentStatus } from '../../../types/shipment';
import { ProjectShippingCarrierRequest } from '../../../types';
import { useTranslation } from 'react-i18next';
import { TableRowEdit } from '../../generic/TableRowEdit';
import { useProjectShipmentActions } from '../../../hooks';
import { useProjectsContext } from '../../../providers';
import { useCallback } from 'react';

type GeneralSectionProps = {
  data: Shipment;
  projectShippingCarriers: ProjectShippingCarrierRequest[] | null;
};

export const GeneralSection: React.FC<GeneralSectionProps> = ({ data, projectShippingCarriers }) => {
  /** Tabs view of parcels with basic data and tracking */
  const { t } = useTranslation(['shipment', 'common']);
  const { updateShipment } = useProjectShipmentActions();
  const { selectedProject: project } = useProjectsContext();

  const localizeShipmentStatus = useCallback(
    (status: ShipmentStatus): string => {
      switch (status) {
        case ShipmentStatus.Imported:
          return t('shipment:status.imported');
        case ShipmentStatus.Sent:
          return t('shipment:status.sent');
        case ShipmentStatus.Error:
          return t('shipment:status.error');
        case ShipmentStatus.Processing:
          return t('shipment:status.processing');
        case ShipmentStatus.Finished:
          return t('shipment:status.finished');
        default:
          return '';
      }
    },
    [t]
  );

  const { id, reference, carrier, carrierService, note, carrierOptions, seller, status } = data;
  return (
    <TableContainer>
      <Table size="sm">
        <Tbody>
          <Tr>
            <Td width="10%">{t('shipment:information.id.label')}</Td>
            <Td>{id}</Td>
          </Tr>
          <Tr>
            <Td width="10%">{t('shipment:information.reference.label')}</Td>
            <Td>{reference}</Td>
          </Tr>
          <Tr>
            <Td width="10%">{t('shipment:carrier.carrier.label')}</Td>
            <Td>{carrier}</Td>
          </Tr>
          {note && (
            <Tr>
              <Td width="10%">{t('shipment:information.note.label')}</Td>
              <Td>{note}</Td>
            </Tr>
          )}

          <TableRowEdit
            row={{
              title: t('shipment:information.status.label'),
              status: status,
            }}
            schema={{
              title: {
                edit: false,
                type: 'string',
                width: '10%',
              },
              status: {
                edit: true,
                type: 'enum',
                options: [
                  {
                    value: ShipmentStatus.Sent,
                    label: localizeShipmentStatus(ShipmentStatus.Sent),
                  },
                  {
                    value: ShipmentStatus.Finished,
                    label: localizeShipmentStatus(ShipmentStatus.Finished),
                  },
                ],
              },
            }}
            onSave={(
              shipment: Record<string, any> // Whole row data
            ) => {
              console.log('shipment', shipment);
              updateShipment(project?.id as string, data.id as string, shipment, true);
            }}
          />
          {carrierService && (
            <Tr>
              <Td width="10%">{t('shipment:carrier.service.label')}</Td>
              <Td>{carrierService}</Td>
            </Tr>
          )}

          {carrierOptions
            ? projectShippingCarriers
                ?.find((item) => item.shippingCarrier.id === carrier)
                ?.shippingCarrier?.shipmentCarrierOptions?.map((option) => {
                  const isArray = option.isArrayOfValues;
                  if (isArray) {
                    const values = carrierOptions[option.key] as string[];
                    return (
                      <Tr>
                        <Td width="10%">{option.label}</Td>
                        <Td>{values.join(', ')}</Td>
                      </Tr>
                    );
                  }
                  return (
                    <Tr>
                      <Td width="10%">{option.label}</Td>
                      <Td>{carrierOptions[option.key]}</Td>
                    </Tr>
                  );
                })
            : null}
          {note && (
            <Tr>
              <Td width="10%">{t('shipment:information.note.label') /* Note */}</Td>
              <Td>{note}</Td>
            </Tr>
          )}
          {seller && (
            <Tr>
              <Td width="10%">{t('shipment:information.seller.label') /* Seller */}</Td>
              <Td>{seller}</Td>
            </Tr>
          )}
        </Tbody>
      </Table>
    </TableContainer>
  );
};
